import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/app/src/assests/img/event/logo_rifCity24.png");
;
import(/* webpackMode: "eager" */ "/app/src/assests/img/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrica"] */ "/app/src/components/YandexMetrica.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/scss/main.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/custom-domain.scss");
